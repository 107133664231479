import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { ToastrModule } from 'ngx-toastr';

import { SharedModule } from './shared/modules';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './shared/services/msal-config.factory';

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        NgbModule,
        NgxSkeletonLoaderModule,
        ReactiveFormsModule,
        SharedModule,
        ToastrModule.forRoot(),
        MsalModule], providers: [
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }